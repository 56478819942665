<template>
    <div class="c3-main c3-bonus-card">
        <div class="c3-page-content" v-if="bonusCard">
            <div class="c3-bonus-card-detail-wrapper">

                <div class="c3-coupon-detail-header-section">
                    <c3-item-media v-if="isCurrentUserTheOwner && (isDraft || isPublished && !isExpired)" :item="bonusCard">
                        <div slot="actionList">
                            <router-link :to="{name:'BonusCardWizard',params:{id:bonusCard.id, action: !isDraft ? 'edit' : ''}}"
                                         class="c3-bonus-card-item-action-list-item">
                                <c3-icon-edit primaryColor="white"/>
                            </router-link>
                        </div>
                    </c3-item-media>
                    <c3-item-media v-else :item="bonusCard" :isFavorite="isFavorite" @toggleFavorite="toggleFavorite"/>
                    <div class="c3-coupon-detail-header-section-content-wrapper">
                        <div class="c3-coupon-detail-header-section-tear-off">
                            <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-top-left"></div>
                            <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-bottom-left"></div>
                            <span>{{$t('bonusCard.name')}}</span>
                        </div>
                        <div class="c3-coupon-detail-header-section-content">
                            <div class="c3-coupon-detail-header-section-content-text">
                                <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-top-right"></div>
                                <div class="c3-coupon-detail-header-section-corner c3-coupon-detail-header-section-corner-bottom-right"></div>

                                <h1 class="c3-coupon-detail-title">{{ bonusCard.titleWithTemplate }}</h1>
                                <c3-stamp-list v-if="bonusCard" :bonusCard="bonusCard"/>
                                <div class="c3-coupon-detail-redeem-state-wrapper">
                                    <strong>{{$t('pages.bonusCard.detail.alreadyReceivedBonuses')}}:</strong>
                                    {{totalBonusCount}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="bonusCard.validUntil" class="c3-coupon-detail-header-section__footer">{{ $t('bonusCard.validUntil') }}: {{formatDateShort(bonusCard.validUntil)}}</div>
                </div>

                <div class="c3-coupon-detail-redeem-wrapper">
                    <c3-button v-if="isReadyForBonus" :label="$t('pages.bonusCard.detail.collectBonus')" @click="scanBonusCardQrCode"/>
                    <c3-button v-else-if="isRedeemable" :label="$t('pages.bonusCard.detail.redeem')" @click="scanBonusCardQrCode"/>
                    <c3-button v-else-if="isDraft && isCurrentUserTheOwner" :label="$t('pages.bonusCard.detail.publish')" styling="action" @click="publishBonusCard"/>
                    <c3-button v-else-if="isInReview" :label="$t('pages.bonusCard.detail.inReview')" styling="disabled"/>
                    <c3-button v-else-if="isNotYetValid" :label="$t('pages.bonusCard.detail.notYetActive')" styling="disabled"/>
                    <c3-button v-else-if="isExpired" :label="$t('pages.bonusCard.detail.expired')" styling="disabled"/>
                    <div @click="showExplanation" class="c3-coupon-redeem-explanation">
                        <c3-icon-question-circle primaryColor="quinary"/>
                        <span>{{$t('pages.bonusCard.detail.explanation')}}</span>
                    </div>
                </div>

                <hr>

                <div class="c3-coupon-detail-section">
                    <h2 class="c3-coupon-detail-company-name">{{bonusCard.shop ? bonusCard.shop.name : bonusCard.user.company}}</h2>
                    <div v-if="bonusCard.shop" class="c3-coupon-detail__shopping-center">{{bonusCard.user.company}}</div>
                    <c3-store v-for="store in storeCollection" :key="store.id" :customer="bonusCard.user" :store="store"/>
                </div>

                <hr/>

                <div class="c3-coupon-detail-section" v-if="bonusCard.description">
                    <c3-column-one-with-icon icon="c3-icon-c3-stamp-circle" class="c3-customer-description">
                        <template slot="first">
                            <strong>{{$t('pages.bonusCard.detail.description')}}</strong><br>
                            <span>{{bonusCard.description}}</span>
                        </template>
                    </c3-column-one-with-icon>
                </div>

                <div class="c3-coupon-detail-section" v-if="bonusCard.legalNotice">
                    <c3-column-one-with-icon icon="c3-icon-info-circle" class="c3-customer-important-information">
                        <template slot="first">
                            <strong>{{$t('pages.bonusCard.detail.legalNotice')}}</strong><br>
                            <span>{{bonusCard.legalNotice}}</span>
                        </template>
                    </c3-column-one-with-icon>
                </div>

                <div class="c3-coupon-detail-section">
                    <div class="c3-coupon-detail-section-action-button">
                        <router-link v-if="bonusCard.user.id" :to="{name:'CustomerPublicBonusCardList',params:{id: bonusCard.user.id}}">
                            <c3-button :label="$t('label.showAllBonusCard')" styling="2"/>
                        </router-link>
                        <router-link v-if="bonusCard.user.id" :to="{name:'CustomerPublicCouponList',params:{id: bonusCard.user.id}}">
                            <c3-button :label="$t('label.showAllCoupons')" styling="2"/>
                        </router-link>
                    </div>
                </div>
            </div>
            <c3-qr-code-reader :qrCodeScanActive="qrCodeScanActive" @encoded="redeem" @cancel="qrCodeScanActive = false" @close="qrCodeScanActive = false"/>
        </div>
    </div>
</template>

<script>
    import {AffiliateService} from '@/domain/service/affiliateService';
    import {Analytics} from '@/domain/analytics';
    import {BonusCardConfig} from '@/config/bonusCard';
    import {DateUtility} from "@/domain/utility/date";
    import {OpeningTimeMixin} from '@/domain/mixin/local/openingTime';
    import {BonusCardPublishMixin} from '@/domain/mixin/local/bonusCard/publish';
    import {BonusCardFavoriteMixin} from '@/domain/mixin/local/bonusCard/favorite';
    import {BonusCardRedeemMixin} from '@/domain/mixin/local/bonusCard/redeem';
    import {ReverseRedeemMixin} from '@/domain/mixin/local/reverseRedeem';

    import C3QrCodeReader from '@/components/content/qrCode/reader/Index';
    import C3ItemMedia from '@/components/content/bonusCard/ItemMedia';
    import C3StampList from '@/components/content/bonusCard/stampList/Index';
    import C3ColumnOneWithIcon from '@/components/layout/column/OneWithIcon';
    import C3CustomerOpeningTimeShort from '@/components/content/customer/OpeningTimeShort';
    import C3Button from '@/components/form/Button.vue';
    import C3Store from '@/components/content/customer/Store';
    import { TYPE_NORMAL } from '@/types';

    export default {
        mixins: [OpeningTimeMixin, BonusCardFavoriteMixin, BonusCardPublishMixin, BonusCardRedeemMixin, ReverseRedeemMixin],
        components: {
            C3QrCodeReader,
            C3ItemMedia,
            C3StampList,
            C3Button,
            C3ColumnOneWithIcon,
            C3CustomerOpeningTimeShort,
            C3Store
        },
        data() {
            return {
                loginRoute: {
                    name: 'LoginForm',
                    query: {
                        'redirectAfterLogin':
                        this.$router.resolve({name: this.$route.name, params: this.$route.params, query: {redeem: 1}}).resolved.fullPath
                    }
                },
                qrCodeScanActive: false,
            }
        },
        computed: {
            bonusCard() {
                let bonusCard = this.$store.state.bonusCard.bonusCards.items[this.$route.params.id];
                if (!bonusCard) {
                    return null;
                }
                bonusCard = Object.assign({}, bonusCard);
                bonusCard.user = this.$store.getters['customer/singleCustomer'](bonusCard.user, {});
                return bonusCard;
            },
            storeCollection() {
                return this.$store.getters['userStore/storeListOrderByZipByUser'](this.bonusCard.user.id);
            },
            isCurrentUserTheOwner() {
                return this.isLoggedIn && this.bonusCard && this.bonusCard.user.id === this.currentUser.id;
            },
        },
        methods: {
            formatDateShort: DateUtility.formatDateShort.bind(DateUtility),
            showExplanation() {
                const contentId = this.bonusCard.type !== TYPE_NORMAL ? 30 : 28;
                this.$c3.staticContentAlertById(contentId);
            },
            redeem(qrToken) {
                this.redeemBonusCard(this.bonusCard.id, qrToken);
            },
            scanBonusCardQrCode() {
                if (this.isLoggedIn) {
                    this.qrCodeScanActive = this.bonusCard.type === TYPE_NORMAL;
                    if (!this.qrCodeScanActive) {
                        this.showReverseRedeemCode();
                    }
                } else {
                    this.qrCodeScanActive = false;
                    this.$c3.confirmLoginRedirect(
                        () => this.$router.push(this.loginRoute)
                    );
                }
            },
            async shareBonusCard() {
                Analytics.logShareBonusCardInit(this.bonusCard.id);
                this.$store.commit('view/showLoading', this.$t('pages.bonusCard.detail.share.loadingMessagePrepareLink'));
                try {
                    const bonusCardImageUrl = BonusCardConfig.getMediaBaseUrl() + '/' + MEDIA.SHARE_SIZE + '/' + this.bonusCard.image.publicName;
                    let url = await AffiliateService.createDeepLink(
                        this.$route.fullPath,
                        this.$route.fullPath,
                        this.bonusCard.titleWithTemplate,
                        this.bonusCard.description,
                        bonusCardImageUrl,
                        'shareBonusCard',
                        '#' + this.bonusCard.id + ' - ' + this.bonusCard.titleWithTemplate
                    );

                    if (!url) {
                        throw new Error('AffiliateService.createDeepLink returned an invalid result: ' + JSON.stringify(url));
                    }

                    Analytics.logShareBonusCard(this.bonusCard.id, url);

                    console.debug('[BonusCard - Detail] shareBonusCard - Start shareWithOptions');
                    let options = {
                        subject: this.$t('pages.bonusCard.detail.share.subject') + ': ' + this.bonusCard.titleWithTemplate,
                        message: this.$t('pages.bonusCard.detail.share.subject') + ': ' + this.bonusCard.titleWithTemplate + ' '+ this.$t('label.from') +' ' + this.bonusCard.user.company,
                        url: url,
                        files: [bonusCardImageUrl]
                    };

                    await new Promise((resolve, reject) => window.plugins.socialsharing.shareWithOptions(options, resolve, reject));
                } catch (e) {
                    console.error('[pages][BonusCard][Detail.vue]', e);
                    this.$c3.errorAlert('pages.bonusCard.detail.share.createDeepLinkFailed.title', 'pages.bonusCard.detail.share.createDeepLinkFailed.message');
                    Logger.captureException(e);
                }
                this.$store.commit('view/hideLoading');
            }
        },
        async mounted() {
            this.$store.commit('view/showLoading');
            await this.$store.dispatch('bonusCard/loadOneBonusCard', this.$route.params.id);
            await this.$store.dispatch('userStore/loadStoreByUser', {userId: this.bonusCard.user.id});
            if (this.$route.query.redeem) {
                this.scanBonusCardQrCode();
            }
            this.$store.commit('view/hideLoading');
        },
        events: {
            [EVENT.BONUS_CARD_SHARE.NAME]: {
                handler() {
                    this.shareBonusCard();
                },
                priority: EVENT.BONUS_CARD_SHARE.PRIORITY.BONUS_CARD_DETAIL
            },
        },
    };
</script>
<style lang="scss" scoped>
    $corner-size: 6px;
    $bonus-card-max-width: calc(100% - 2 * var(--page-content-horizontal-spacing));

    .c3-main {
        background-color: $nonary-color;
    }

    .c3-page-content {
        // Fix iOS white screen during scroll: https://stackoverflow.com/q/9807620
        -webkit-transform: translate3d(0, 0, 0);
    }

    .c3-coupon-detail-header-section {
        margin-bottom: 30px;
    }

    .c3-coupon-detail-header-section-content-wrapper {
        position: relative;
        display: flex;
        max-width: $bonus-card-max-width;
        margin: -70px auto 0 auto;

        @media(min-width: $bp-sm+1) {
            width: 70vw;
            max-width: 400px;
        }
    }

    .c3-coupon-detail-header-section-tear-off {
        position: relative;
        width: 35px;
        padding: $corner-size 0;
        background-color: $quinary-color;
        background-clip: content-box;
        color: $white-color;

        span {
            position: absolute;
            bottom: 55px;
            left: -35px;
            transform: rotate(-90deg);
            @include montserratMedium();
            font-size: 16px;
        }
    }

    .c3-coupon-detail-header-section-content {
        position: relative;
        flex: 1;
        min-height: 150px;
        padding: $corner-size 0;
        background-clip: content-box;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: $corner-size;
            display: block;
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            @include boxShadow();
            pointer-events: none;
            z-index: -1;
        }
    }

    .c3-coupon-detail-header-section-content-text {
        padding: 10px 15px 10px 15px;
        background-color: $white-color;
    }

    .c3-coupon-detail-header-section-corner {
        position: absolute;
        display: block;
        height: 10px;
        width: 100%;
        background-clip: content-box;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            height: $corner-size * 4;
            width: $corner-size * 4;
            border-radius: 50%;
            border: $corner-size solid transparent;
        }

        &.c3-coupon-detail-header-section-corner-top-left {
            top: 0;
            left: 0;
            padding-right: $corner-size;
            border-top-left-radius: 5px;
            background-color: $quinary-color;

            &:after {
                top: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $quinary-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-top-right {
            top: 0;
            right: 0;
            padding-left: $corner-size;
            border-top-right-radius: 5px;
            background-color: $white-color;

            &:after {
                top: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-bottom-right {
            bottom: 0;
            right: 0;
            padding-left: $corner-size;
            border-bottom-right-radius: 5px;
            background-color: $white-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                left: -1 * $corner-size * 2;
                border-color: $white-color;
            }
        }

        &.c3-coupon-detail-header-section-corner-bottom-left {
            bottom: 0;
            left: 0;
            padding-right: $corner-size;
            border-bottom-left-radius: 5px;
            background-color: $quinary-color;

            &:after {
                bottom: -1 * $corner-size * 2;
                right: -1 * $corner-size * 2;
                border-color: $quinary-color;
            }
        }
    }

    .c3-coupon-detail-header-section__footer {
        max-width: $bonus-card-max-width;
        margin: 5px auto 0;

        color: $fifth-color;
        text-align: right;
        font-size: $font-size-xs;
    }

    .c3-coupon-detail-section {
        padding-left: var(--page-content-horizontal-spacing);
        padding-right: var(--page-content-horizontal-spacing);
    }

    .c3-coupon-detail-title {
        margin-bottom: 15px;
        @include openSansBold();
        font-size: 18px;
        color: $quinary-color;
    }

    .c3-coupon-detail-redeem-state-wrapper {
        margin-top: 15px;
        color: $fifth-color;
    }

    .c3-coupon-detail-redeem-wrapper {
        width: 100%;
        max-width: 250px;
        margin: 20px auto 20px;
        text-align: center;

        span {
            font-size: 12px;
            color: $senary-color;
        }
    }

    .c3-coupon-redeem-explanation {
        padding-top: 10px;

        .c3-icon-wrapper {
            position: relative;
            top: 3px;
            height: 16px;
            margin-right: 5px;
        }

        span {
            color: $primary-color;
            font-size: 12px;
        }
    }

    .c3-navigation-link {
        display: block;
    }

    .c3-coupon-detail-company-name {
        font-size: 18px;
    }

    .c3-customer-address {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .c3-customer-description,
    .c3-customer-important-information {
        margin-bottom: 30px;

        strong {
            display: inline-block;
            @include openSansBold();
            padding-bottom: 10px;
        }

        span {
            hyphens: auto;
            word-break: break-all;
        }
    }

    .c3-coupon-detail-section-action-button {
        a, div {
            display: block;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
</style>
