<template>
    <div class="c3-main" ref="scrollContainer">
        <div class="c3-page-content">
            <c3-bonus-card-list :name="$route.name" :showStaticItems="true" :items="items">
                <template slot="header">
                    <c3-bonus-card-list-header :name="$route.name" :title="title" :filter="true"/>
                </template>
            </c3-bonus-card-list>
        </div>
    </div>
</template>

<script>
    import C3BonusCardList from '@/components/content/bonusCard/list/Index';
    import C3BonusCardListHeader from '@/components/content/bonusCard/list/Header';

    export default {
        name: 'BonusCardList',
        components: {
            C3BonusCardList,
            C3BonusCardListHeader,
        },
        data() {
            return {
                fallbackItems: [],
                isLoading: false,
            }
        },
        computed: {
            title() {
                let title = this.$t('pages.bonusCard.list.title');
                if (this.currentUser.firstName) {
                    title += ',<br>' + this.currentUser.firstName + '!';
                }
                return title;
            },
            items() {
                let items = this.$store.getters['viewBonusCardList/items'](this.$route.name);
                items = items.map(item => {
                    let bonusCard = this.$store.state.bonusCard.bonusCards.items[item.id];
                    return {
                        ...bonusCard,
                        ...item
                    };
                });
                if (items.length) {
                    // Update fallback items
                    this.fallbackItems = items;
                } else if (this.isLoading) {
                    // Use fallback items, because store delivers an empty list and we currently load new bonusCards
                    // This avoids an empty list, after position change (position changed filter, but bonusCards are not loaded yet)
                    items = this.fallbackItems;
                }

                return items;
            }
        },
        methods: {
            async loadData(showLoading, forceReload) {
                this.isLoading = true;
                if (showLoading) {
                    this.$store.commit('view/showLoading', this.$t('bonusCard.loadingList'));
                }

                try {
                    // Wait for position update, otherwise the position could change AFTER we load the bonusCard which causes an empty list
                    if (showLoading && this.$store.getters['viewBonusCardList/useCurrentLocation'](this.$route.name)) {
                        await this.$store.dispatch('viewBonusCardList/position', {name: this.$route.name, addressTimeout: 2500});
                    } else {
                        await this.$store.dispatch('viewBonusCardList/position', {name: this.$route.name});
                    }
                } catch (e) {
                    console.error('[BonusCard/List] loadData failed', e);
                }

                try {
                    await this.$store.dispatch('viewBonusCardList/loadItems', {name: this.$route.name, forceReload: forceReload});
                } catch (e) {
                    console.error('[BonusCard/List] loadData failed', e);
                }

                this.isLoading = false;
                if (showLoading) {
                    this.$store.commit('view/hideLoading');
                }
            },
            async loadInitial() {
                if (0 === this.items.length) {
                    await this.loadData(true);
                    this.restoreScrollPosition();
                } else {
                    this.restoreScrollPosition();
                    await this.loadData(false);
                }
                this.restoreScrollPosition();
            },
            restoreScrollPosition() {
                let scrollContainer = this.$refs.scrollContainer;
                if (null !== scrollContainer && this.$store.state.view.scrollPosition[this.$route.name]) {
                    scrollContainer.scrollTop = this.$store.state.view.scrollPosition[this.$route.name];
                }
            }
        },
        mounted() {
            this.loadInitial();
        },
        beforeRouteLeave(to, from, next) {
            let scrollContainer = this.$refs.scrollContainer;
            if (null !== scrollContainer) {
                this.$store.commit('view/saveScrollPosition', {name: [this.$route.name], position: scrollContainer.scrollTop});
            }
            next();
        },
        events: {
            [EVENT.REFRESH.NAME]: {
                async handler() {
                    await this.loadData(true, true);
                    try {
                        // Jump to top
                        this.$refs.scrollContainer.scrollTop = 0;
                    } catch (e) {
                        console.error('Reset scrollPosition to 0 failed', e);
                    }
                },
                priority: EVENT.REFRESH.PRIORITY.BONUS_CARD_LIST
            },
        },
    };
</script>
<style lang="scss" scoped>
    .c3-main {
        background-color: $nonary-color;
    }

    .c3-bonus-card-list-wrapper {
        margin-top: 15px;
    }
</style>
