import moment from 'moment';
import {BonusCardConfig} from '@/config/bonusCard';

export const BonusCardPublishMixin = {
    data() {
        return { type: 'bonusCard.name' }
    },
    computed: {
        isDraft() {
            return this.bonusCard.state === BonusCardConfig.STATE_DRAFT;
        },
        isInReview() {
            return this.bonusCard.state === BonusCardConfig.STATE_REVIEW;
        },
        isPublished() {
            return this.bonusCard.state === BonusCardConfig.STATE_PUBLISHED;
        },
        isNotYetValid() {
            return this.isPublished && moment() < this.bonusCard.validFrom;
        },
        isExpired() {
            return this.isPublished && moment() > this.bonusCard.validUntil;
        },
        hasPermissionToPublish() {
            return this.$store.getters['user/hasMembershipAndPayment'];
        },
        hasFreeMembership() {
            return this.$store.getters['user/hasFreeMembership'];
        },
        hasMembership() {
            return this.$store.getters['user/hasMembership'];
        },
        publishBonusCardRoute() {
            const params = this.$route.params;
            if (!params.hasOwnProperty('id') && this.bonusCard.id) {
                // Add ID of the bonus card to the redirectAfterSave route - Otherwise publish wouldn't know which bonus card to publish
                params.id = this.bonusCard.id;
            }
            let currentRouteWithPublishParam = this.$router.resolve({name: this.$route.name, params: this.$route.params, query: {publishBonusCard: 1}}).resolved.fullPath;
            const routeName = this.hasMembership ? 'CustomerRegistrationPayment' : 'CustomerRegistrationMembership';
            return {name: routeName, query: {redirectAfterSave: currentRouteWithPublishParam}};
        },
        membershipStatus() {
            const hasPayment = !!this.$store.getters['user/payment'];
            const hasNonFreeMembership = this.$store.getters['user/hasNonFreeMembership'];
            
            if (!hasPayment && hasNonFreeMembership) {
                return 'hasNoPaymentWithNonFreeMembership';
            } else {
                return 'hasNoMembership';
            }
        }

    },
    methods: {
        async publishBonusCard() {
            // Check if payment and membership is available
            this.$store.commit('view/showLoading');

            try {
                await Promise.all([
                    this.$store.dispatch('user/loadCurrentPayment'),
                    this.$store.dispatch('user/loadActiveMemberships'),
                ]);
            } catch (e) {
                console.error(e);
            }

            this.$store.commit('view/hideLoading');
            if (this.hasPermissionToPublish || this.hasFreeMembership) {
                this.askPublishBonusCardConfirmation()
            } else {
                this.$c3.confirmYesNo(
                    this.$t(`publishAlert.${this.membershipStatus}.title`),
                    this.$t(`publishAlert.${this.membershipStatus}.message`, { type: this.$t(this.type) }),
                    () => this.$router.push(this.publishBonusCardRoute)
                );
            }
        },
        askPublishBonusCardConfirmation() {
            this.$c3.confirmYesNo(
                'Sammelpass veröffentlichen',
                'Alle Sammelpässe werden auf Missbrauch und Fehler geprüft. Möchtest du deinen Sammelpass jetzt zur Überprüfung senden?',
                this.sendPublishBonusCard
            );
        },
        sendPublishBonusCard() {
            this.$store.dispatch('userBonusCard/enableReview', {id: this.bonusCard.id})
                .then(() => {
                    this.$c3.successAlert(
                        'Danke für deine Anfrage',
                        'Dein Sammelpass wird nun überprüft. Sobald die Prüfung abgeschlossen ist, informieren wir dich per E-Mail.',
                        () => this.$router.push({name: 'BonusCardDetail', params: {id: this.bonusCard.id}})
                    );
                })
                .catch((error) => {
                    this.$c3.errorAlert('error.noConnection.title', 'error.noConnection.message');
                    console.error(error);
                })
                .finally(() => this.$store.commit('view/hideLoading'));
        }
    },
    mounted() {
        if (this.$route.query.publishBonusCard) {
            this.publishBonusCard();
        }
    }
};
