<template>
    <div class="c3-bonus-card__redeem-list" :data-col="colCount">
        <div v-for="redeem in currentCycleRedeems" :key="redeem.id" class="c3-bonus-card__redeem-list-item c3-bonus-card__redeem-list-item--redeemed">
            <div class="c3-bonus-card__redeem-list-item-content">
                <c3-icon-check-circle primaryColor="white"/>
                <div class="c3-bonus-card__redeem-list-item-date">{{redeem.created.format('DD.MM')}}</div>
            </div>
        </div>

        <div v-if="null !== remainingRedeemCount" v-for="i in remainingRedeemCount" :key="`placeholder-${i}`" class="c3-bonus-card__redeem-list-item">
            <div class="c3-bonus-card__redeem-list-item-content">
                <c3-icon-check-circle primaryColor="white"/>
                <div class="c3-bonus-card__redeem-list-item-date">&nbsp;</div>
            </div>
        </div>
    </div>
</template>
<script>
    import {BonusCardRedeemMixin} from '@/domain/mixin/local/bonusCard/redeem';

    export default {
        mixins: [BonusCardRedeemMixin],
        components: {},
        props: {
            bonusCard: {
                type: Object,
                required: true
            }
        },
        computed: {
            colCount() {
                if (0 === this.bonusCard.necessaryRedeemCount % 5) {
                    return 5;
                } else if (0 === this.bonusCard.necessaryRedeemCount % 4) {
                    return 4;
                } else {
                    return 3;
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    $bonus-card-redeem-gutter: 10px;

    .c3-bonus-card__redeem-list {
        display: flex;
        flex-wrap: wrap;

        margin-top: -1 * $bonus-card-redeem-gutter;
        margin-left: -1 * $bonus-card-redeem-gutter;

        &[data-col="3"] .c3-bonus-card__redeem-list-item {
            width: calc(33.333% - #{$bonus-card-redeem-gutter});
            padding-top: calc(33.333% - #{$bonus-card-redeem-gutter});
        }

        &[data-col="4"] .c3-bonus-card__redeem-list-item {
            width: calc(25% - #{$bonus-card-redeem-gutter});
            padding-top: calc(25% - #{$bonus-card-redeem-gutter});
        }

        &[data-col="5"] .c3-bonus-card__redeem-list-item {
            width: calc(20% - #{$bonus-card-redeem-gutter});
            padding-top: calc(20% - #{$bonus-card-redeem-gutter});
        }
    }

    .c3-bonus-card__redeem-list-item {
        position: relative;
        height: 0;
        margin-top: $bonus-card-redeem-gutter;
        margin-left: $bonus-card-redeem-gutter;
    }

    .c3-bonus-card__redeem-list-item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-color: #DEDEDE;
    }

    .c3-bonus-card__redeem-list-item--redeemed {
        .c3-bonus-card__redeem-list-item-content {
            background-color: $quinary-color;
        }
    }

    .c3-icon-wrapper {
        display: flex;
        justify-content: center;
        flex: 1;
        overflow: hidden;
    }

    .c3-bonus-card__redeem-list-item-date {
        position: relative;
        text-align: center;
        color: $white-color;
    }

    .c3-bonus-card__redeem-list[data-col="3"] .c3-bonus-card__redeem-list-item {
        .c3-icon-wrapper {
            margin-top: 8px;
            margin-bottom: 8px;
            @media(max-width: $bp-xs) {
                margin-bottom: 4px;
            }
        }

        .c3-bonus-card__redeem-list-item-date {
            margin-bottom: 2px;
            font-size: 14px;
        }
    }

    .c3-bonus-card__redeem-list[data-col="4"] .c3-bonus-card__redeem-list-item {
        .c3-icon-wrapper {
            margin-top: 6px;
            margin-bottom: 8px;
            @media(max-width: $bp-xs) {
                margin-top: 5px;
                margin-bottom: 4px;
            }
        }

        .c3-bonus-card__redeem-list-item-date {
            margin-bottom: 2px;
            font-size: 10px;
        }
    }

    .c3-bonus-card__redeem-list[data-col="5"] .c3-bonus-card__redeem-list-item {
        .c3-icon-wrapper {
            margin-top: 6px;
            margin-bottom: 6px;
            @media(max-width: $bp-s) {
                margin-top: 5px;
                margin-bottom: 4px;
            }
            @media(max-width: $bp-xs) {
                margin-top: 3px;
                margin-bottom: 2px;
            }
        }

        .c3-bonus-card__redeem-list-item-date {
            margin-bottom: 2px;
            font-size: 10px;

            @media(max-width: $bp-xs) {
                font-size: 9px;
            }
        }
    }
</style>
